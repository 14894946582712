import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import addAnotherPc from "../../assets/img/addAnotherPc.png";
// @ts-ignore
import checkmark from "../../assets/img/benefitsCheckIcon.png";
// @ts-ignore
import diagnoseIcon from "../../assets/img/computerDiagnose_large.png";
// @ts-ignore
import defaultPC from "../../assets/img/defaultPC.png";
// @ts-ignore
import updateIcon from "../../assets/img/driverUpdatesIcon.png";
// @ts-ignore
import optimizationIcon from "../../assets/img/optimizationsIcon.png";
// @ts-ignore
import phoneIcon from "../../assets/img/phoneSupportIcon.png";
// @ts-ignore
import warningIcon from "../../assets/img/warningIcon.png";
// @ts-ignore
import windowsIcon from "../../assets/img/windowsIcon.png";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { MachineViewModel } from "../viewModels/MachineViewModel";
import { CancelProgressBar } from "./CancelProgressBar";


@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class CancelSubscription extends React.Component<Partial<IComponentProps>, undefined>{
    private machineList: MachineViewModel[];

    constructor(props) {
        super(props);
        this.onEndBenefitsClicked = this.onEndBenefitsClicked.bind(this);
        this.onKeepBenefitsClicked = this.onKeepBenefitsClicked.bind(this);
        this.onViewDetailsClicked = this.onViewDetailsClicked.bind(this);
        //get the first 2 PCs
        this.machineList = this.props.viewStore.allMachines.slice(0, 2);
    }

    public render() {
        try {
            return <div className="cancelSubscriptionWrapper" id="cancelSubscriptionWrapper">
                <CancelProgressBar step={1} />
                <div className="firstCancelPageHeader appPanel defaultMaterial" id="firstCancelPageHeader">
                    <img className="scanImage" id="scanImage" src={diagnoseIcon} />
                    <h2 className="firstCancelPageHeaderText">You've saved countless hours updating, optimizing, and fixing your PCs
 by maintaining your subscription.  Do you want to end your benefits?</h2>
                </div>
                {this.machineList && this.machineList.length > 0 &&
                    <div className="firstCancelPageMachineCoverage appPanel defaultMaterial" id="firstCancelPageMachineCoverage">
                        <div className="headerArea">
                            <img className="warningIcon" id="firstCancelPageMachineWarningIcon" src={warningIcon} />
                            <h3 className="headerText" id="firstCancelPageMachineHeaderText">These computers tied to your subscription will no longer be covered if you cancel.</h3>
                        </div>
                        <div className="machineArea" id="firstCancelPageMachineArea">
                            <MachineTile vm={this.machineList[0]} isAddMachine={false} isFirst={true} viewDetailsCB={this.onKeepBenefitsClicked} addAnotherPCCB={null} />
                            {this.machineList.length > 1 &&
                                <MachineTile vm={this.machineList[1]} isAddMachine={false} isFirst={false} viewDetailsCB={this.onKeepBenefitsClicked} addAnotherPCCB={null} />
                            }
                            <MachineTile vm={null} isAddMachine={true} isFirst={false} viewDetailsCB={null} addAnotherPCCB={this.onViewDetailsClicked} />
                        </div>
                    </div>
                }
                <div className="currentBenefits appPanel defaultMaterial" id="cancelSubscriptionFirstPageYourBenefits">
                    <h2 className="header" id="cancelSubscriptionFirstPageBenefitsHeader">Your Current Benefits</h2>
                    <div className="benefitsList" id="cancelSubscriptionFirstPageBenefitsList">
                        <div className="benefitsRow">
                            <img className="benefitsIcon" src={phoneIcon} />
                            <p className="benefitsText">Direct hotline to unlimited tech support for any issue 7 days a week</p>
                            <div className="contentLine" />
                            <img className="checkMark" src={checkmark} />
                        </div>
                        <div className="benefitsRow">
                            <img className="benefitsIcon" src={updateIcon} />
                            <p className="benefitsText">Unlimited driver updates for all your PC devices</p>
                            <div className="contentLine" />
                            <img className="checkMark" src={checkmark} />
                        </div>
                        <div className="benefitsRow">
                            <img className="benefitsIcon" src={windowsIcon} />
                            <p className="benefitsText">Ongoing Windows improvements for all your PCs</p>
                            <div className="contentLine" />
                            <img className="checkMark" src={checkmark} />
                        </div>
                        <div className="benefitsRow">
                            <img className="benefitsIcon" src={optimizationIcon} />
                            <p className="benefitsText">Realtime app optimizations for the apps you use the most</p>
                            <div className="contentLine" />
                            <img className="checkMark" src={checkmark} />
                        </div>
                    </div>
                </div>
                <div className="cancelContinueChallenge appPanel defaultMaterial" id="cancelContinueChallenge">
                    <h2 className="cancelContinueChallengeHeader" id="cancelContinueChallengeHeader">Are you sure you want to cancel and lose these benefits?</h2>
                    <div className="cancelButtonArea" id="cancelButtonArea">
                        <button type="button" className="appButton grey endBenefitsButton cancelStepButton" id="endBenefitsButton" onClick={this.onEndBenefitsClicked}>End My Benefits</button>
                        <div className="cancelButtonDivider" id="cancelButtonDivider"></div>
                        <button type="button" className="appButton green keepBenefitsButton cancelStepButton" id="keepBenefitsButton" onClick={this.onKeepBenefitsClicked}>Keep My Benefits</button>
                    </div>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering cancel Subscription ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    public componentDidMount() {
        //prevent invalid subscription navigation and prevent navigation to this page when subscription is already cancelled
        let selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
        if (!selectedSubscription || selectedSubscription.IsActive == false) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
            this.props.dispatcher.DispatchAction(action);
        }
    }

    private onEndBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_ADVANCE, AppViews.Overview);
        this.props.dispatcher.DispatchAction(action);
    }

    private onKeepBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_BACK, null);
        this.props.dispatcher.DispatchAction(action);
    }

    private onViewDetailsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.PCSelector);
        this.props.dispatcher.DispatchAction(action);
    }
}

interface MachineTileProps {
    vm: MachineViewModel;
    isAddMachine: boolean;
    isFirst: boolean;
    viewDetailsCB: any;
    addAnotherPCCB: any;
}

export const MachineTile: React.StatelessComponent<MachineTileProps> = (props) => {
    let firstClass = "";
    if (props.isFirst) {
        firstClass = " first"
    }
    return <div className={`machineTile${firstClass}`}>
        {!props.isAddMachine &&
            <div>
                {props.vm.machineImageUrl != null ? (
                    <img className="machineImage" id="cancelMachineTileMachineImage" src={props.vm.machineImageUrl} />
                ) : (
                        <img className="machineImage defaultPCImage" src={defaultPC} />
                    )}
                <p className="machineName" id="cancelMachineTileMachineName">{props.vm.machineName}</p>
                <p className="viewDetails" id="cancelMachineTileViewDetails" onClick={props.viewDetailsCB}>View Details</p>
            </div>
        }
        {props.isAddMachine &&
            <div>
                <img className="addAnotherPc" src={addAnotherPc} />
                <p className="addAnotherPcLabel" onClick={props.addAnotherPCCB}>Add Another PC</p>
            </div>
        }

    </div>;
}
